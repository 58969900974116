<template>
  <div class="pay-list">
    <div class="pay-list-title">支付列表</div>
    <div class="pay-list-body" v-if="list.length">
      <div class="pay-list-head">
        <div class="pay-list-head-name">
          <p>已签约合同</p>
          <span v-if="isMul">分笔支付</span>
        </div>
        <div v-if="list[0]&&list[0].contractType!=5" class="pay-list-head-detail" @click="$router.push({path:'/contract',query:{contractId:contractId}})">
          <span>详情</span><img src="../assets/images/arrow-r.svg" alt="" />
        </div>
      </div>
      <div class="pay-list-item" v-for="(item,index) in list" :key="index">
        <span v-if="isMul">分笔数{{ index+1 }}</span>
        <span v-else>支付金额</span>
        <p v-if="isMul">金额：￥{{ item.payAmount }}</p>
        <p v-else>￥{{ item.payAmount }}</p>
        
        <img
          class="hasPay"
          v-if="item.payStatus == 1"
          src="../assets/images/stamp.svg"
          alt=""
        />
        <div class="noPay" v-else-if="item.payStatus == 0" @click="$router.push({path:`/pay`,query:{contractId:contractId,payOrderNo:item.payOrderNo,isMul:list.length}})">
          <i>待支付</i>
          <img src="../assets/images/arrow-r-pay.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant"
export default {
  name: "PayList",
  data() {
    return {
      isMul: false,
      contractId:this.$route.query.contractId,
      list: [],
    };
  },
  created() {
      if (!this.$route.query.contractId||this.$route.query.contractId=="undefined") {
      Toast({
        message:"缺少合同编号",
        icon:'warning',
        duration:3000
      })
      return false
    }
    this.handleGetPayList()
  },
  methods: {
    handleGetPayList(){
     const loading=Toast.loading({
        duration: 2000, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      this.$http.get("/front/paymentDetails",{contractCode:this.$route.query.contractId}).then((res) => {
        if (res.code === 1) {
              loading.clear();
              this.list=[...res.data]
              this.isMul=res.data&&res.data.length>1
          } else {
            loading.clear()
            Toast({
              icon: "clear",
              forbidClick:true,
              message: res.message,
              duration: 2000,
            });
          }
      }).catch((err) => {
        loading.clear()
        console.log(err);
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pay-list {
  width: 100%;
  min-height: 100%;
  padding: 0 12px;
  background: #f3f4f7;
  background-image: url("../assets/images/contract-bg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  font-size: 16px;
  overflow: hidden;
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-body {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
  }
  &-head {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 16px;
    border-bottom: 1px solid #eeeeee;
    &-name {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 0;
      flex: 1;
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 8px;
      }
      span {
        display: block;
        width: 68px;
        height: 22px;
        background: rgba(50, 118, 255, 0.05);
        border-radius: 2px;
        border: 1px solid #3276ff;
        text-align: center;
        line-height: 20px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3276ff;
        white-space: nowrap;
      }
    }
    &-detail {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      span {
        display: block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3276ff;
        margin-right: 5px;
        white-space: nowrap;
      }
      img {
        display: block;
        height: 12px;
      }
    }
  }
  &-item {
    width: 100%;
    height: 71px;
    padding: 14px 15px;
    overflow: hidden;
    position: relative;
    &:not(:nth-last-of-type(1)) {
      border-bottom: 1px solid #eeeeee;
    }
    & > span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: block;
      margin-bottom: 5px;
      white-space: nowrap;
    }
    & > p {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }
    .hasPay {
      display: block;
      width: 64px;
      position: absolute;
      top: 0px;
      right: -5px;
      white-space: nowrap;
    }
    .noPay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      i {
        font-weight: 400;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fa326e;
        font-style: normal;
        line-height: 1;
        white-space: nowrap;
      }
      img {
        display: block;
        height: 12px;
        margin-left: 5px;
      }
    }
  }
}
</style>
